import { useViewModel } from '~/client/application/modal/useViewModel'
import { CommonModal } from '~/components/modals/CommonModal'

export const CommonModalContainer = () => {
  const { current, onCloseCurrent } = useViewModel((state) => ({
    current: state.current,
    onCloseCurrent: state.onCloseCurrent,
  }))

  if (current === null) {
    return null
  }

  return <CommonModal config={current} close={onCloseCurrent} />
}
