import { useCallback } from 'react'
import { Button } from '../Button'
import type { ModalConfig } from '~/client/model/modal/types'

interface Props {
  config: ModalConfig
  close: () => void
}

export const CommonModal = (props: Props) => {
  const { config, close } = props

  const onButton1Click = useCallback(() => {
    if (config === null) {
      return
    }
    config.button1.onClick().finally(() => {
      close()
    })
  }, [close, config])

  const onButton2Click = useCallback(() => {
    if (config === null) {
      return
    }
    if (config.button2 === undefined) {
      return
    }
    config.button2.onClick().finally(() => {
      close()
    })
  }, [close, config])

  return (
    <div className="fixed bg-[rgba(0,0,0,0.8)] backdrop-blur w-full h-full left-0 top-0 z-[99] text-black font-bold">
      <div className="px-2 absolute top-1/2 md:-translate-x-1/2 md:left-1/2 -translate-y-1/2 w-full md:w-auto">
        <div className="bg-white w-full md:w-auto md:max-w-[400px] md:mx-auto my-2 md:my-8 rounded p-1 px-3 md:px-6 max-h-[calc(100%-64px)] overflow-scroll">
          <div className="border-b-2 border-b-black relative flex items-center justify-center">
            <h2 className="text-primary my-5">{config.title}</h2>
          </div>
          <div className="py-4 whitespace-pre-wrap">{config.content}</div>
          <div className="flex justify-center mb-4">
            <Button
              className="mx-2 min-w-[80px] flex-1"
              onClick={onButton1Click}
              variant={config.button1.variant}
            >
              {config.button1.label}
            </Button>
            {config.button2 !== undefined && (
              <Button
                className="mx-2 min-w-[80px] flex-1"
                onClick={onButton2Click}
                variant={config.button2.variant}
              >
                {config.button2.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
